import React, { useState } from "react";
import PageHeader from "../components/page-header";
import { Link } from "react-router-dom";
import Features1 from "../assets/images/tc-lite/features-travel-crm.png";
import Features2 from "../assets/images/tc-lite/features-itinerary-builder.png";
import Features3 from "../assets/images/tc-lite/features-invoice.png";
import Features4 from "../assets/images/tc-lite/features-accounting-reconciliation.png";
import Features5 from "../assets/images/tc-lite/features-customer-portal.png";
import Features6 from "../assets/images/tc-lite/features-agency-management.png";
import Features7 from "../assets/images/tc-lite/features-marketplace.png";
import Features8 from "../assets/images/tc-lite/features-ai.png";
import Features9 from "../assets/images/tc-lite/features-packages.png";
import Features10 from "../assets/images/tc-lite/features-reports-analytics.png";
import BulletPlus from "../assets/images/tc-lite/bullet-plus-orange.png";
import BulletMinus from "../assets/images/tc-lite/bullet-minus-orange.png";
import HowitworksMobile from "../assets/images/tc-lite/howitworks-mobile.gif";

import Reason1 from "../assets/images/tc-lite/reasons-icon-1.png";
import Reason2 from "../assets/images/tc-lite/reasons-icon-2.png";
import Reason3 from "../assets/images/tc-lite/reasons-icon-3.png";
import Reason4 from "../assets/images/tc-lite/reasons-icon-4.png";

const TravelCarmaLite = () => {
  const [active, setActive] = useState("tab1");
  const [activeTab, setActiveTab] = useState("tab1");
  return (
    <div className="page-inside page-twlite">
      <PageHeader page="travelcarma-lite" />

      <div className="container">
        <div className="mt-5">
          <div className="row">
            <div className="col-lg-6">
              <ul className="home-feature-box list-unstyled row text-center">
                <li className="col-lg-4 mb-4">
                  <div
                    className={active === "tab1" ? "active" : ""}
                    onClick={() => setActive("tab1")}
                  >
                    <img
                      src={Features1}
                      alt="Travel CRM"
                      style={{ height: "48px" }}
                    />
                    <h5 className="mb-0 mt-3">
                      Travel <br />
                      CRM
                    </h5>
                  </div>
                </li>
                <li className="col-lg-4 mb-4">
                  <div
                    className={active === "tab2" ? "active" : ""}
                    onClick={() => setActive("tab2")}
                  >
                    <img
                      src={Features2}
                      alt="Itinerary Builder"
                      style={{ height: "48px" }}
                    />
                    <h5 className="mb-0 mt-3">Itinerary Builder</h5>
                  </div>
                </li>
                <li className="col-lg-4 mb-4">
                  <div
                    className={active === "tab3" ? "active" : ""}
                    onClick={() => setActive("tab3")}
                  >
                    <img
                      src={Features3}
                      alt="Invoicing Module"
                      style={{ height: "48px" }}
                    />
                    <h5 className="mb-0 mt-3">Invoicing Module</h5>
                  </div>
                </li>
                <li className="col-lg-4 mb-4">
                  <div
                    className={active === "tab4" ? "active" : ""}
                    onClick={() => setActive("tab4")}
                  >
                    <img
                      src={Features4}
                      alt="Accounting & Reconciliation"
                      style={{ height: "48px" }}
                    />
                    <h5 className="mb-0 mt-3">Accounting & Reconciliation</h5>
                  </div>
                </li>
                <li className="col-lg-4 mb-4">
                  <div
                    className={active === "tab5" ? "active" : ""}
                    onClick={() => setActive("tab5")}
                  >
                    <img
                      src={Features5}
                      alt="Travel Website"
                      style={{ height: "48px" }}
                    />
                    <h5 className="mb-0 mt-3">
                      Travel
                      <br />
                      Website
                    </h5>
                  </div>
                </li>
                <li className="col-lg-4 mb-4">
                  <div
                    className={active === "tab6" ? "active" : ""}
                    onClick={() => setActive("tab6")}
                  >
                    <img
                      src={Features6}
                      alt="Agency Management"
                      style={{ height: "48px" }}
                    />
                    <h5 className="mb-0 mt-3">Agency Management</h5>
                  </div>
                </li>
                <li className="col-lg-4 mb-4">
                  <div
                    className={active === "tab7" ? "active" : ""}
                    onClick={() => setActive("tab7")}
                  >
                    <img
                      src={Features7}
                      alt="B2B Marketplace"
                      style={{ height: "48px" }}
                    />
                    <h5 className="mb-0 mt-3">B2B Marketplace</h5>
                  </div>
                </li>
                <li className="col-lg-4 mb-4">
                  <div
                    className={active === "tab8" ? "active" : ""}
                    onClick={() => setActive("tab8")}
                  >
                    <img
                      src={Features8}
                      alt="TravelCarmaAI Lite"
                      style={{ height: "48px" }}
                    />
                    <h5 className="mb-0 mt-3">
                      TravelCarma
                      <br />
                      AI Lite
                    </h5>
                  </div>
                </li>
                {/* <li className="col-lg-4 mb-4">
                  <div>
                    <img
                      src={Features9}
                      alt="Package Module"
                      style={{ height: "48px" }}
                    />
                    <h5 className="mb-0 mt-3">Package Module</h5>
                  </div>
                </li> */}
                <li className="col-lg-4 mb-4">
                  <div
                    className={active === "tab9" ? "active" : ""}
                    onClick={() => setActive("tab9")}
                  >
                    <img
                      src={Features10}
                      alt="Reports & Analytics"
                      style={{ height: "48px" }}
                    />
                    <h5 className="mb-0 mt-3">Reports & Analytics</h5>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <div className="home-feature-section ps-4">
                <h2 className="text-primary mb-4">Features</h2>
                <h5 className="text-secondary mb-5">We Offer</h5>

                {active === "tab1" && (
                  <div>
                    <h3 className="text-primary">Travel CRM</h3>
                    <p className="text-secondary mt-3">
                      TravelCarmaLite provides key details of all your leads and
                      customers at your fingertips and to help you create fully
                      personalized quotations and itineraries for them in
                      minutes. With TravelCarmaLite you can streamline your
                      marketing activities and maximize bookings.
                    </p>
                    <ul className="list-unstyled mt-4">
                      <li>
                        Have customer & supplier details at your fingertips
                      </li>
                      <li>Capture inquiries from multiple sources</li>
                      <li>
                        Allocate inquiries and track progress & communication
                      </li>
                      <li>Get follow-up alerts for inquiries and proposals</li>
                    </ul>
                  </div>
                )}

                {active === "tab2" && (
                  <div>
                    <h3 className="text-primary">Itinerary Builder</h3>
                    <p className="text-secondary mt-3">
                      TravelCarmaLite makes the process of creating itineraries
                      and proposals a breeze! Build multi-day itineraries with
                      rich content in just a few clicks and share them with your
                      clients as a PDF/Link via Email, Whatsapp etc.
                    </p>
                    <ul className="list-unstyled mt-4">
                      <li>
                        Look up Global Flight/Hotel/Activities/Transfers with
                        details
                      </li>
                      <li>Set up Pricing at Service Level or Package Level</li>
                      <li>Copy & Reuse itineraries for other customers</li>
                      <li>
                        All your Itineraries and Quotations are saved in one
                        place
                      </li>
                    </ul>
                  </div>
                )}
                {active === "tab3" && (
                  <div>
                    <h3 className="text-primary">Invoicing Module</h3>
                    <p className="text-secondary mt-3">
                      <b>Never lose on payments with our GST-ready invoices!</b>
                      <br />
                      You can now create and manage invoices through our
                      Invoicing module. This feature helps travel agents to
                      create, print, and share invoices with their customers and
                      also collect payments. With this feature, you can add
                      details of invoices, all services, prices, and guests so
                      that you do not miss out on any important details.
                    </p>
                    <ul className="list-unstyled mt-4">
                      <li>Create & Manage invoices in one place</li>
                      <li>The most efficient way to get GST-ready invoices</li>
                      <li>All of your client's invoices at one place</li>
                    </ul>
                  </div>
                )}
                {active === "tab4" && (
                  <div>
                    <h3 className="text-primary">
                      Accounting & Reconciliation
                    </h3>
                    <p className="text-secondary mt-3">
                      TravelCarmaLite simplifies your accounting and minimizes
                      costly manual errors by automatically reconciling receipts
                      & payments with suppliers and customers. It can handle
                      accounting for both offline bookings & bookings made in
                      third-party systems, giving you total flexibility.
                    </p>
                    <ul className="list-unstyled mt-4">
                      <li>Invoice generation based on proposals</li>
                      <li>Manage all your customer payables & receivables</li>
                      <li>Customer & Supplier Ledgers and Statements</li>
                      <li>
                        Debit & credit memos for offline cancellations, last
                        minute issues etc.
                      </li>
                    </ul>
                  </div>
                )}
                {active === "tab5" && (
                  <div>
                    <h3 className="text-primary">Travel Website</h3>
                    <p className="text-secondary mt-3">
                      <b>
                        Get your own mobile-friendly CMS-based website at a
                        fraction of the cost of getting it developed outside!
                      </b>
                      <br />
                      Showcase your packages and deals to customers, allow them
                      to send inquiries, view itineraries, invoices, bookings
                      and even make payments against invoices online!
                    </p>
                    <ul className="list-unstyled mt-4">
                      <li>Ready templates to choose from</li>
                      <li>
                        Easily customizable with your logo, branding and content
                      </li>
                      <li>No additional hosting charges</li>
                      <li>Mapping with your own domain</li>
                    </ul>
                  </div>
                )}

                {active === "tab6" && (
                  <div>
                    <h3 className="text-primary">Agency Management</h3>
                    <p className="text-secondary mt-3">
                      With TravelCarmaLite you can manage various aspects of
                      your agency including your branch details, employees and
                      suppliers from one place to run your business more
                      efficiently.
                    </p>
                    <ul className="list-unstyled mt-4">
                      <li>
                        Profile setup for branding & customer documentation
                      </li>
                      <li>Set up currency, GST No, CIN No for invoicing</li>
                      <li>
                        Centrally set up & manage employees across multiple
                        branches
                      </li>
                      <li>Manage agent credit & track their transactions</li>
                    </ul>
                  </div>
                )}

                {active === "tab7" && (
                  <div>
                    <h3 className="text-primary">B2B Marketplace</h3>
                    <p className="text-secondary mt-3">
                      TravelCarmaLite, a leading B2B travel marketplace that
                      offers a wide range of travel products and services to
                      travel agents, tour operators, and travel companies or
                      "buyers".
                    </p>
                    <ul className="list-unstyled mt-4">
                      <li>
                        And best of all TravelCarmaLite b2B Marketplace access
                        is completely free
                      </li>
                      <li>
                        Share Marketplace deals with your customers through your
                        CRM
                      </li>
                      <li>No more losing customers to OTA's</li>
                      <li>Maximize your revenues</li>
                    </ul>
                  </div>
                )}

                {active === "tab8" && (
                  <div>
                    <h3 className="text-primary">TravelCarma AI Lite</h3>
                    <p className="text-secondary mt-3">
                      The lightning fast itinerary builder. Don't miss out on
                      the chance to experience the incredible potential of AI
                      for travel planning.
                    </p>
                    <ul className="list-unstyled mt-4">
                      <li>
                        TravelCarmaAI Lite uses artificial intelligence to
                        generate personalized itineraries based on your queries
                        or prompts.
                      </li>
                    </ul>
                  </div>
                )}

                {active === "tab9" && (
                  <div>
                    <h3 className="text-primary">Reports & Analytics</h3>
                    <p className="text-secondary mt-3">
                      TravelCarmaLite automatically generates a wide range of
                      reports to help you monitor your agency's performance to
                      make informed business decisions. No more hassle of
                      manually entering data and configuring complex formulae in
                      Excel to find out how your business is doing.
                    </p>
                    <ul className="list-unstyled mt-4">
                      <li>Consolidated view of inquiries from all sources</li>
                      <li>Bookings, sales and revenue reports</li>
                      <li>Customer-wise collection and outstanding reports</li>
                      <li>Supplier payment report</li>
                    </ul>
                  </div>
                )}

                <Link
                  className="btn btn-primary mt-3"
                  to="https://lite.travelcarma.com"
                  target="_blank"
                >
                  Start Free
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center mt-5">
          <h2 className="mb-5">TravelCarmaLite is for</h2>
          <ul className="shadow-box list-unstyled row">
            <li className="col-lg-4 mb-3">
              <div>
                <h3 className="text-primary">Travel Agents</h3>
                <p className="text-secondary mb-0">
                  Efficiently manage your travel agency with TravelCarmaLite,
                  Provide an OTA like experience
                </p>
              </div>
            </li>
            <li className="col-lg-4 mb-3">
              <div>
                <h3 className="text-primary">DMC's+Tour operators</h3>
                <p className="text-secondary mb-0">
                  Create beautiful branded itineraries, email / whatsapp quotes,
                  impress customers close deals
                </p>
              </div>
            </li>
            <li className="col-lg-4 mb-3">
              <div>
                <h3 className="text-primary">Content Partners</h3>
                <p className="text-secondary mb-0">
                  Place offers, promotions on marketplace, Directly connect with
                  agencies, increase reach
                </p>
              </div>
            </li>
          </ul>
        </div>

        <div className="mt-5 tclite-howitworks">
          <h2 className="text-center mb-4">See How it Works</h2>
          <h5 className="text-center text-primary">
            Say goodbye to spreadsheets and manage your business with ease
          </h5>

          <div className="row mt-3">
            <div className="col-lg-5 text-center">
              <img
                className="tclite-howitworks-info-img"
                src={HowitworksMobile}
                alt="See How it Works"
              />
            </div>
            <div className="col-lg-7">
              <div className="tclite-howitworks-info pt-4">
                <div>
                  <h3 onClick={() => setActiveTab("tab1")}>
                    {activeTab === "tab1" ? (
                      <img src={BulletMinus} />
                    ) : (
                      <img src={BulletPlus} />
                    )}
                    Never Lose an Inquiry
                  </h3>
                  {activeTab === "tab1" && (
                    <ul>
                      <li>
                        Capture and manage all your inquiries in one place
                      </li>
                      <li>
                        Store details like service type (flight/hotel/package
                        etc), source(phone/email/whatsapp etc), trip type
                        (domestic/international), duration, budget and special
                        requirements
                      </li>
                      <li>
                        You can also import your past inquiries for centralized
                        tracking
                      </li>
                    </ul>
                  )}
                </div>

                <div>
                  <h3 onClick={() => setActiveTab("tab2")}>
                    {activeTab === "tab2" ? (
                      <img src={BulletMinus} />
                    ) : (
                      <img src={BulletPlus} />
                    )}
                    Build Impressive Itineraries & Proposals
                  </h3>

                  {activeTab === "tab2" && (
                    <ul>
                      <li>
                        Create an itinerary or quotation based on inquiry in a
                        few clicks
                      </li>
                      <li>
                        Use our in-built database to look up and add flights,
                        accommodation, activities, transportation etc (with your
                        own rates), or add your own content
                      </li>
                      <li>
                        Based on what you add, TravelCarmaLite automatically
                        generates a beautiful itinerary with photos, details and
                        your branding
                      </li>
                      <li>Share via email, web link or PDF</li>
                    </ul>
                  )}
                </div>

                <div>
                  <h3 onClick={() => setActiveTab("tab3")}>
                    {activeTab === "tab3" ? (
                      <img src={BulletMinus} />
                    ) : (
                      <img src={BulletPlus} />
                    )}
                    Elevate your Customer Experience
                  </h3>

                  {activeTab === "tab3" && (
                    <ul>
                      <li>
                        Our Travel CRM simplifies customer management and helps
                        you deliver a superior experience to your clients
                      </li>
                      <li>
                        Set up all your leads & customers with their personal
                        details, contact details, passport info, credit limit
                        etc, or import your existing data
                      </li>
                      <li>
                        Use this data to create personalized proposals and
                        emails
                      </li>
                      <li>
                        Track customer-wise inquiries, itineraries, quotations,
                        invoices and bookings
                      </li>
                    </ul>
                  )}
                </div>

                <div>
                  <h3 onClick={() => setActiveTab("tab4")}>
                    {activeTab === "tab4" ? (
                      <img src={BulletMinus} />
                    ) : (
                      <img src={BulletPlus} />
                    )}
                    Automate your Accounting
                  </h3>

                  {activeTab === "tab4" && (
                    <ul>
                      <li>
                        TravelCarmaLite automates your accounting and makes it
                        super easy to track your finances
                      </li>
                      <li>
                        Create proposals and generate invoices from the system
                      </li>
                      <li>
                        Make bookings against those invoices offline or in a
                        third-party system
                      </li>
                      <li>
                        Record the payments and receipts for those bookings in
                        TravelCarmaLite and get error-free accounting and
                        reconciliation reports
                      </li>
                    </ul>
                  )}
                </div>

                <div>
                  <h3 onClick={() => setActiveTab("tab5")}>
                    {activeTab === "tab5" ? (
                      <img src={BulletMinus} />
                    ) : (
                      <img src={BulletPlus} />
                    )}
                    Always Stay on Top of Your Business
                  </h3>

                  {activeTab === "tab5" && (
                    <ul>
                      <li>
                        TravelCarmaLite automatically generates reports based on
                        your usage
                      </li>
                      <li>
                        Every time you log in you get a dashboard which shows
                        the latest inquiries, itineraries, quotations and
                        bookings to give you a bird’s-eye-view
                      </li>
                      <li>
                        You can also get in-depth reports to accurately track
                        inquiries, bookings, sales, collections, supplier
                        payments and much more..
                      </li>
                      <li>
                        All reports include advanced filters to help you quickly
                        find what you want
                      </li>
                    </ul>
                  )}
                </div>

                <div>
                  <h3 onClick={() => setActiveTab("tab6")}>
                    {activeTab === "tab6" ? (
                      <img src={BulletMinus} />
                    ) : (
                      <img src={BulletPlus} />
                    )}
                    Impress your clients with stunning packages!
                  </h3>

                  {activeTab === "tab6" && (
                    <ul>
                      <li>All your Packages are stored in one place</li>
                      <li>
                        Build detailed packages in one place that can be
                        retrieved in just a click.
                      </li>
                      <li>
                        Easily send packages via a mobile-friendly link to your
                        customers
                      </li>
                    </ul>
                  )}
                </div>

                <div>
                  <h3 onClick={() => setActiveTab("tab7")}>
                    {activeTab === "tab7" ? (
                      <img src={BulletMinus} />
                    ) : (
                      <img src={BulletPlus} />
                    )}
                    Never lose on payments with our GST-ready invoices!
                  </h3>

                  {activeTab === "tab7" && (
                    <ul>
                      <li>Never lose on payments!</li>
                      <li>Create & Manage invoices in one place</li>
                      <li>Get GST-ready invoices in a simple way</li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <h2 className="text-center mb-4">
            4 More Reasons to Switch to TravelCarmaLite
          </h2>
          <h5 className="text-primary text-center">
            No Obligations | No Credit Card Required | No Commitments
          </h5>
          <p className="text-center">
            And lots of help to get you set up and expand your network for
            growth
          </p>
          <ul className="shadow-box list-unstyled row mt-5">
            <li className="col-lg-3 mb-3">
              <div className="text-center">
                <img
                  className="mt-3"
                  src={Reason1}
                  alt="TourWiz - Web & Mobile Friendly"
                />
                <p className="text-secondary mb-0 mt-4">
                  Web & mobile-friendly to help you manage your agency from
                  anywhere
                </p>
              </div>
            </li>
            <li className="col-lg-3 mb-3">
              <div className="text-center">
                <img
                  className="mt-3"
                  src={Reason2}
                  alt="TourWiz - High Data Security"
                />
                <p className="text-secondary mb-0 mt-4">
                  High data security & 99.9% uptime guaranteed by Amazon cloud
                  hosting
                </p>
              </div>
            </li>
            <li className="col-lg-3 mb-3">
              <div className="text-center">
                <img className="mt-3" src={Reason3} alt="TourWiz - No APIs" />
                <p className="text-secondary mb-0 mt-4">
                  You don't need to have your own booking system/supplier APIs
                  to use it
                </p>
              </div>
            </li>
            <li className="col-lg-3 mb-3">
              <div className="text-center">
                <img
                  className="mt-3"
                  src={Reason4}
                  alt="TourWiz - Easy on the pocket"
                />
                <p className="text-secondary mb-0 mt-4">
                  Easy on the pocket - Available for a very low monthly
                  subscription
                </p>
              </div>
            </li>
          </ul>
          <div className="text-center">
            <Link
              className="btn btn-primary mt-3"
              to="https://lite.travelcarma.com"
              target="_blank"
            >
              Start Free
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TravelCarmaLite;
